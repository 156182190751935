import {
  collection,
  collectionGroup,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { XIcon } from '@heroicons/react/outline';
import Footer from '../layouts/Footer';
import Header from '../layouts/Header';
import { firestore } from '../lib/firebase';
import { envVersionPath, postToJSON } from '../lib/helper';
import styles from '../styles/Home.module.scss';
import Metatags from '../components/parts/Metatags';
import Heading from '../components/parts/Heading';
import PostCard from '../components/parts/PostCard';
import CompanyCard from '../components/parts/CompanyCard';

// ISR
export async function getStaticProps() {
  const versionPath = envVersionPath;

  const companyDocs = query(
    collection(firestore, `${versionPath}/publicCompanies`),
    where('withOpen', '==', true),
    orderBy('updatedAt', 'desc'),
    limit(12),
  );

  const postDocs = query(
    collectionGroup(firestore, 'posts'),
    where('withOpen', '==', true),
    orderBy('updatedAt', 'desc'),
    limit(6),
  );

  const tagDocs = query(
    collection(firestore, `${versionPath}/tags`),
    orderBy('times', 'desc'),
    limit(20),
  );

  const promises = [getDocs(companyDocs), getDocs(postDocs), getDocs(tagDocs)];

  const snapshots = await Promise.all(promises);

  const [companySnapshot, postSnapshot, tagSnapShot] = snapshots;

  const companies = companySnapshot.docs.map(postToJSON);
  const posts = postSnapshot.docs.map(postToJSON);
  const tags = tagSnapShot.docs.map(postToJSON);

  return {
    props: { companies, posts, tags },
    revalidate: 10,
  };
}

// …………………………………………………………………………………………………………………………………………………………………………………………………………
const Home = ({ companies, posts, tags }) => {
  const [renderCompanies, setRenderCompanies] = useState([]);
  const [renderPosts, setRenderPosts] = useState([]);
  const [renderTags, setRenderTags] = useState([]);
  const [selectedTag, setSelectedTag] = useState('');
  const versionPath = envVersionPath;

  // ISR後の初期値設定とselectedTagをクリアした時のリセット処理
  useEffect(() => {
    if (!selectedTag) {
      setRenderCompanies(companies);
      setRenderPosts(posts);
    }
  }, [companies, posts, selectedTag]);

  useEffect(() => {
    if (!selectedTag) {
      setRenderTags(tags);
    }
  }, [tags, selectedTag]);

  useEffect(() => {
    if (selectedTag) {
      const { tagName } = selectedTag;

      const companyDocs = query(
        collection(firestore, `${versionPath}/publicCompanies`),
        where('tags', 'array-contains', tagName),
        where('withOpen', '==', true),
        orderBy('updatedAt', 'desc'),
        limit(6),
      );

      const postDocs = query(
        collectionGroup(firestore, 'posts'),
        where('tags', 'array-contains', tagName),
        where('withOpen', '==', true),
        orderBy('updatedAt', 'desc'),
        limit(6),
      );

      const promises = [getDocs(companyDocs), getDocs(postDocs)];

      Promise.all(promises).then(snapshots => {
        const [companySnapshot, postSnapshot] = snapshots;

        setRenderCompanies(companySnapshot.docs.map(postToJSON));
        setRenderPosts(postSnapshot.docs.map(postToJSON));
      });

      setRenderTags([selectedTag]);
    }
  }, [selectedTag, versionPath]);

  return (
    <div className={styles.container}>
      <Metatags
        title="SUB NOTE - サブノート | 政策課題に民間企業のノウハウを"
        description="「サブノート」は民間企業が持つ【ノウハウ】や【アイディア】を、【政策課題】と結びつけて、官公庁にPRできるWEBサービスです。"
        image="/images/ogp.png"
      />

      <Header />

      <main>
        <div className={styles.key}>
          <div className={styles.inner}>
            <div className={styles.box}>
              <p className={styles.catch}>
                <span>
                  <span>政策課題に</span>
                </span>
                <span>
                  <span>民間企業の</span>
                </span>
                <span>
                  <span>ノウハウを</span>
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="mx-auto max-w-7xl px-8 pt-20">
          <section>
            <Heading title="事例・提案" subArea="New Post" />
            <div className="noteCard-list">
              {renderPosts.map((post, index) => {
                return <PostCard key={index} info={post} />;
              })}
            </div>
          </section>

          <section className="mt-24">
            <Heading title="企業" subArea="New Company" />
            <div className="conpanyCrad-list">
              {renderCompanies.map((company, index) => {
                return <CompanyCard key={index} info={company} />;
              })}
            </div>
          </section>

          <section className="my-24">
            <Heading title="キーワード" subArea="Keywords" />
            <div className="">
              {renderTags.map((tag, index) => {
                const { tagName, times } = tag;
                return (
                  <div
                    className="mr-2 mt-4 inline-flex items-center rounded-full border-2 border-snGreen px-6 py-2 font-medium"
                    key={tagName + index}
                  >
                    <button type="button" onClick={() => setSelectedTag(tag)}>
                      {tagName}
                      <span className="ml-2 scale-75">( {times} )</span>
                    </button>
                    {tagName === selectedTag.tagName && (
                      <button type="button" onClick={() => setSelectedTag('')}>
                        <XIcon className="w- ml-4 h-4" aria-hidden="true" />
                      </button>
                    )}
                  </div>
                );
              })}
            </div>
          </section>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Home;
