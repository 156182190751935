import Link from 'next/link';
import React from 'react';
import HeaderButtons from '../components/parts/HeaderButtons';

const Header = ({ buttons = true }) => {
  return (
    <header className="bg-snBlue">
      <nav className="mx-auto max-w-7xl px-8" aria-label="Top">
        <div className="flex h-[60px] w-full items-center justify-between">
          <h1 className="fontMain flex items-center text-[30px] text-white">
            <Link href="/">
              <a>SUB NOTE</a>
            </Link>
          </h1>

          <div className="ml-10 space-x-4">{buttons && <HeaderButtons />}</div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
