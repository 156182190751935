/* eslint-disable react/no-unstable-nested-components */
import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-snBlue">
      <div className="mx-auto max-w-7xl px-8 pt-4 pb-4">
        <div className="flex items-center justify-between">
          <p className="mt-0 text-base text-white">
            &copy; 2024 AKNO Co.Ltd All rights reserved.
          </p>
          <div className="space-x-6 text-xs">
            <a
              href="https://sub-note.notion.site/SUB-NOTE-f9610704118347dc8afef4f2a664c351"
              className="text-white"
              target="_blank"
              rel="noreferrer"
            >
              利用規約
            </a>
            <a
              href="https://sub-note.notion.site/SUB-NOTE-45b603fa03ac4f12a685b26533f9238e"
              className="text-white"
              target="_blank"
              rel="noreferrer"
            >
              プライバシーポリシー
            </a>
            <a
              href="https://www.akno.co.jp/"
              className="text-white"
              target="_blank"
              rel="noreferrer"
            >
              運営:株式会社AKNO
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
