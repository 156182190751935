import Head from 'next/head';
import Script from 'next/script';

const Metatags = ({
  title = 'SUB NOTE - サブノート | 政策課題に民間企業のノウハウを',
  description = '「サブノート」は民間企業が持つ【ノウハウ】や【アイディア】を、【政策課題】と結びつけて、官公庁にPRできるWEBサービスです。',
  image = '/images/ogp.png',
  noindex = false,
}) => {
  let host;
  if (typeof window !== 'undefined') {
    host = window && window.location.hostname;
  }

  return (
    <Head>
      <title>{title}</title>
      <Script
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-PH8PTS7');
          `,
        }}
      />
      <meta name="description" content={description} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="https://www.sub-note.com/" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
      {(host === 'test.sub-note.com' || noindex) && (
        <meta name="robots" content="noindex" />
      )}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
    </Head>
  );
};

export default Metatags;
