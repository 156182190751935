import Link from 'next/link';

const PostCard = ({ info, fromAdmin, arrow }) => {
  const {
    postType,
    title,
    companyName,
    companyUrlName,
    postUrlName,
    tags,
    createdAt,
  } = info;
  return (
    <div className={`noteCard ${postType}`}>
      <Link
        href={`/${companyUrlName}/post/${postUrlName}`}
        prefetch={!fromAdmin}
      >
        <a>
          <div className="category">
            {postType === 'case' && '事例'}
            {postType === 'proposal' && '提案'}
          </div>
          <p className="title">{title}</p>
          <ul className="tagList">
            {tags.map((tag, index) => {
              return <li key={index}>#{tag}</li>;
            })}
          </ul>
          <div className="info">
            <p className="company">{companyName}</p>
            <p className="date">{new Date(createdAt).toLocaleDateString()}</p>
          </div>
          {arrow && <div className="linkBtn mt-4">詳細を見る</div>}
        </a>
      </Link>
    </div>
  );
};

export default PostCard;
