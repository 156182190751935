import { signOut } from 'firebase/auth';
import { useRouter } from 'next/router';
import toast from 'react-hot-toast';
import { useUserAuth } from '../../context/userAuth.context';
import { useUserProfile } from '../../context/userProfile.context';
import { auth } from '../../lib/firebase';
import { useModalSettings } from '../../context/modalSettings.context';

const HeaderButtons = () => {
  const userProfile = useUserProfile();
  const { open, setModalType } = useModalSettings();

  const { uid, type } = userProfile || {};

  if (uid && type === 'companyUser') {
    return <CompanyUserButtons />;
  }

  return (
    <>
      <button
        type="button"
        className="inline-block rounded-md border border-transparent bg-snBlue  text-base font-medium text-white hover:bg-snBlueDark"
      >
        <a
          href="https://sub-note.notion.site/392c06e69870430fba48801c2a746658"
          target="_blank"
          rel="noreferrer"
        >
          <p className="py-2 px-4">官公庁の方</p>
        </a>
      </button>
      <button
        type="button"
        onClick={() => {
          setModalType('company-sign-in-or-register');
          open();
        }}
        className="inline-block rounded-md border border-transparent bg-snBlue py-2 px-4 text-base font-medium text-white hover:bg-snBlueDark"
      >
        企業の方
      </button>
    </>
  );
};

export default HeaderButtons;

// …………………………………………………………………………………………………………………………………………………………………………………………………………
const CompanyUserButtons = () => {
  const { setAuthObj } = useUserAuth();
  const router = useRouter();
  const userProfile = useUserProfile();

  const { companyUrlName } = userProfile;

  return (
    <>
      <button
        type="button"
        onClick={() => {
          router.push(`/${companyUrlName}/admin/mypage`);
        }}
        className="inline-block rounded-md border border-transparent bg-snBlue py-2 px-4 text-base font-medium text-white hover:bg-snBlueDark"
      >
        マイページ
      </button>
      <button
        type="button"
        onClick={async () => {
          await signOut(auth);
          setAuthObj(null);
          return toast.success('サインアウトしました。');
        }}
        className="inline-block rounded-md border border-transparent bg-snGray py-2 px-4 text-base font-medium text-white hover:bg-opacity-80"
      >
        サインアウト
      </button>
    </>
  );
};
