import Link from 'next/link';
import React from 'react';
import Image from 'next/image';

const CompanyCard = ({ info, fromAdmin }) => {
  const { companyName, companyHeaderImageUrl, category, companyUrlName } = info;
  return (
    <div className="conpanyCrad-item">
      <Link href={`/${companyUrlName}`} prefetch={!fromAdmin}>
        <a>
          <figure className="img">
            <Image
              src={companyHeaderImageUrl}
              alt="企業イメージ"
              layout="fill"
              objectFit="cover"
              priority
            />
          </figure>
          <div className="info">
            <p className="company-name">{companyName}</p>
            <p className="category">{category}</p>
          </div>
        </a>
      </Link>
    </div>
  );
};

export default CompanyCard;
