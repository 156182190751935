import React from 'react';

const Heading = ({ title, subArea }) => {
  return (
    <div className="heading mb-10">
      <div className="flex items-end leading-none">
        <h1 className="title text-snBlue">{title}</h1>
        <div className="sub-title fontMain ml-8 translate-y-[3px] font-bold tracking-widest">
          {subArea}
        </div>
      </div>
    </div>
  );
};

export default Heading;
